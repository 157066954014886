a {
  color: #337ab7;
  text-decoration: none;
}

.mode-toggle {
  position: absolute;
  right: 20%;
  top: 40px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.linkedin {
  fill: #2867B2;
}

.github {
  fill: #2867B2;
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}
